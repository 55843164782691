/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable no-descending-specificity */
@use 'src/styles/base.scss' as *;

/* autoprefixer grid: autoplace */

.wrapper {
  grid-area: 3 / 1 / 4 / 2;
  position: relative;
  z-index: 2;

  @include lg {
    grid-area: 2 / 2 / 4 / 3;
  }
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: white;
  z-index: 60;

  .active & {
    @include with-motion {
      animation: video-fade 1.2s forwards;
    }

    opacity: 1;
  }

  .roo & {
    background-color: #030161;
  }

  .aesop & {
    background-color: #fcfef4;
  }
}

.inner {
  width: 71vw;
  height: 47vw;
  cursor: pointer;

  @include md {
    width: 53.25vw;
    height: 36.25vw;
  }

  @include lg {
    width: 35.5vw;
    height: 23.5vw;
  }
}

:global(.notSafari) .inner {
  @include with-motion {
    transition: transform ease-in-out 600ms;
  }
}

:global(.notSafari) .inactive .inner {
  @include with-motion {
    transform: none !important;
  }
}

.notion {
  .inner {
    transform: translate(12vw, -10vw) rotate(-4deg);
    z-index: 40;
    border: 1px solid darkgray;

    @include md {
      transform: translate(7vw, -2.5vw) rotate(-4deg);
    }
  }

  &.active .inner {
    @include notion-animation;
  }
}

:global(.notSafari) .notion .inner {
  @include with-motion {
    &:hover {
      // transform: translate(7.5vw, -3vw) rotate(-4deg);
      transform: translate(12vw, -10vw) rotate(-2deg);
    }
  }

  @include md {
    @include with-motion {
      &:hover {
        transform: translate(7vw, -2.5vw) rotate(-2deg);
      }
    }
  }
}

.ikea {
  .inner {
    transform: translate(-11.5vw, -27vw) rotate(15deg);
    z-index: 30;
    border: 1px solid slateblue;

    @include md {
      transform: translate(-5.5vw, -10vw) rotate(15deg);
    }
  }

  &.active .inner {
    @include ikea-animation;
  }
}

:global(.notSafari) .ikea .inner {
  @include with-motion {
    &:hover {
      // transform: translate(-6vw, -10.5vw) rotate(15deg);
      transform: translate(-11.5vw, -27vw) rotate(13deg);
    }
  }

  @include md {
    @include with-motion {
      &:hover {
        transform: translate(-5.5vw, -10vw) rotate(13deg);
      }
    }
  }
}

.roo {
  .inner {
    transform: translate(-22vw, 14vw) rotate(-33deg);
    z-index: 20;

    @include md {
      transform: translate(-9vw, 4vw) rotate(-23deg);
    }
  }

  &.active .inner {
    @include roo-animation;
  }
}

:global(.notSafari) .roo .inner {
  @include with-motion {
    &:hover {
      // transform: translate(-9.5vw, 4.5vw) rotate(-23deg);
      transform: translate(-22vw, 14vw) rotate(-35deg);
    }
  }

  @include md {
    @include with-motion {
      &:hover {
        transform: translate(-9vw, 4vw) rotate(-25deg);
      }
    }
  }
}

.aesop {
  .inner {
    transform: translate(10vw, 19.5vw) rotate(38.2deg);
    z-index: 10;
    border: 1px solid tan;

    @include md {
      transform: translate(10vw, 5.5vw) rotate(38.2deg);
    }
  }

  &.active .inner {
    @include aesop-animation;
  }
}

:global(.notSafari) .aesop .inner {
  @include with-motion {
    &:hover {
      // transform: translate(10.5vw, 6vw) rotate(38.2deg);
      transform: translate(10vw, 19.5vw) rotate(40deg);
    }
  }

  @include md {
    @include with-motion {
      &:hover {
        transform: translate(10vw, 5.5vw) rotate(40deg);
      }
    }
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
}
