@use 'animations' as *;

@mixin with-motion {
  @media (prefers-reduced-motion: no-preference) {
    @content;
  }
}

@mixin uppercase-type {
  font-family: Karla, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}

@mixin large-type {
  font-size: 18px;
  line-height: 25px;
}

@mixin button-reset {
  appearance: none;
  background: unset;
  border: unset;
  color: black;
}

@mixin inline-button {
  @include button-reset;

  text-decoration: underline;
}

@mixin underline-animation {
  @include with-motion {
    &:hover {
      animation: underline-fade 2s ease-in-out infinite;
    }
  }
}

@mixin notion-animation {
  @include with-motion {
    animation: shuffle-1-sm 800ms forwards;

    @include md {
      animation: shuffle-1-md 800ms forwards;
    }

    @include lg {
      animation: shuffle-1-lg 800ms forwards;
    }
  }
}

@mixin ikea-animation {
  @include with-motion {
    animation: shuffle-2-sm 1.2s forwards;

    @include md {
      animation: shuffle-2-md 1.2s forwards;
    }

    @include lg {
      animation: shuffle-2-lg 1.2s forwards;
    }
  }
}

@mixin roo-animation {
  @include with-motion {
    animation: shuffle-3-sm 1.2s forwards;

    @include md {
      animation: shuffle-3-md 1.2s forwards;
    }

    @include lg {
      animation: shuffle-3-lg 1.2s forwards;
    }
  }
}

@mixin aesop-animation {
  @include with-motion {
    animation: shuffle-4-sm 1.2s forwards;

    @include md {
      animation: shuffle-4-md 1.2s forwards;
    }

    @include lg {
      animation: shuffle-4-lg 1.2s forwards;
    }
  }
}
