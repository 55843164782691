@import 'https://fonts.googleapis.com/css?family=Karla:regular,bold,italic';
@import-normalize;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: Karla, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  margin: unset;
  padding: unset;
  appearance: none;
  border: none;
}
