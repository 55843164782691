@keyframes underline-fade {
  0% {
    text-decoration-color: rgb(0 0 0 / 100%);
  }

  50% {
    text-decoration-color: rgb(0 0 0 / 0%);
  }

  100% {
    text-decoration-color: rgb(0 0 0 / 100%);
  }
}

@keyframes video-fade {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin shuffleAnimation($top, $left: null, $width: 35.5vw, $height: 23.5vw) {
  0% {
    margin-top: 0;
    margin-left: 0;

    @content;
  }

  25% {
    @if $top {
      margin-top: $top;
    }

    @if $left {
      margin-left: $left;
    }
  }

  50% {
    transform: translate(0, 0) rotate(0deg);
    margin-top: 0;
    margin-left: 0;
    z-index: 50;
  }

  60% {
    transform: translate(0, 0) rotate(0deg);
    width: $width;
    height: $height;
    z-index: 50;
    border-width: 1px;
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
    width: 100%;
    height: 100%;
    z-index: 50;
    border-width: 0;
  }
}

@keyframes shuffle-1-lg {
  @include shuffleAnimation($top: -10vw) {
    transform: translate(7vw, -2.5vw) rotate(-4deg);
  }
}

@keyframes shuffle-1-md {
  @include shuffleAnimation($top: -20vw, $width: 53.25vw, $height: 36.25vw) {
    transform: translate(7vw, -2.5vw) rotate(-4deg);
  }
}

@keyframes shuffle-1-sm {
  @include shuffleAnimation($top: -20vw, $width: 71vw, $height: 47vw) {
    transform: translate(12vw, -10vw) rotate(-4deg);
  }
}

@keyframes shuffle-2-lg {
  @include shuffleAnimation($top: -50vw, $left: -40vw) {
    transform: translate(-5.5vw, -10vw) rotate(15deg);
  }
}

@keyframes shuffle-2-md {
  @include shuffleAnimation(
    $top: -100vw,
    $left: -80vw,
    $width: 53.25vw,
    $height: 36.25vw
  ) {
    transform: translate(-5.5vw, -10vw) rotate(15deg);
  }
}

@keyframes shuffle-2-sm {
  @include shuffleAnimation(
    $top: -100vw,
    $left: -80vw,
    $width: 71vw,
    $height: 47vw
  ) {
    transform: translate(-11.5vw, -27vw) rotate(15deg);
  }
}

@keyframes shuffle-3-lg {
  @include shuffleAnimation($top: 50vw, $left: -40vw) {
    transform: translate(-9vw, 4vw) rotate(-23deg);
  }
}

@keyframes shuffle-3-md {
  @include shuffleAnimation(
    $top: 100vw,
    $left: -80vw,
    $width: 53.25vw,
    $height: 36.25vw
  ) {
    transform: translate(-9vw, 4vw) rotate(-23deg);
  }
}

@keyframes shuffle-3-sm {
  @include shuffleAnimation(
    $top: 100vw,
    $left: -80vw,
    $width: 71vw,
    $height: 47vw
  ) {
    transform: translate(-22vw, 14vw) rotate(-33deg);
  }
}

@keyframes shuffle-4-lg {
  @include shuffleAnimation($top: 50vw, $left: 40vw) {
    transform: translate(10vw, 5.5vw) rotate(38.2deg);
  }
}

@keyframes shuffle-4-md {
  @include shuffleAnimation(
    $top: 100vw,
    $left: 80vw,
    $width: 53.25vw,
    $height: 36.25vw
  ) {
    transform: translate(10vw, 5.5vw) rotate(38.2deg);
  }
}

@keyframes shuffle-4-sm {
  @include shuffleAnimation(
    $top: 100vw,
    $left: 80vw,
    $width: 71vw,
    $height: 47vw
  ) {
    transform: translate(10vw, 19.5vw) rotate(38.2deg);
  }
}
