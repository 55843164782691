@use 'src/styles/base.scss' as *;

/* autoprefixer grid: autoplace */

.wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px auto 1fr 50px;
  height: 100%;
  overflow: hidden;
  min-height: 860px;

  @include lg {
    grid-template-columns: minmax(320px, 33%) auto;
    grid-template-rows: 50px 1fr 50px;
    min-height: 500px;
  }
}

.topBar {
  height: 50px;
  grid-area: 1 / 1 / 2 / 2;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include lg {
    grid-area: 1 / 1 / 2 / 3;
  }
}

.close {
  padding: 7px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-in-out 300ms;
  background: none;
  cursor: pointer;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.sideBar {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 2 / 1 / 3 / 2;
  border-bottom: 1px solid black;
  z-index: 1;

  @include lg {
    grid-area: 1 / 1 / 4 / 2;
    border-right: 1px solid black;
    border-bottom: none;
  }
}

.bottomBar {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid black;

  @include lg {
    grid-area: 3 / 1 / 4 / 2;
    z-index: 20;
  }
}

.name {
  @include uppercase-type;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
  font-weight: normal;
}

.description {
  width: 100%;
  text-align: center;
  padding: 20px 20px 30px;
  background: white;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &.outline {
    border-color: black;
  }

  p {
    @include large-type;

    padding: 0 calc((100% - 385px) / 2);
  }

  @include with-motion {
    transition: border-color 300ms ease-in-out;
  }

  @include lg {
    width: 450px;
    padding: 20px 20px 50px;
    background: none;
    border: none;
  }
}

.clients {
  padding: 0 calc((100% - 400px) / 2);
}

.linkedin {
  @include inline-button;
  @include uppercase-type;
  @include underline-animation;

  padding: 10px;
}
