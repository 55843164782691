@use 'src/styles/base.scss' as *;

.button {
  @include inline-button;
  @include large-type;
  @include underline-animation;

  margin: 0;
  padding: 0;
  cursor: pointer;
}
